import React, { useState, useEffect } from 'react'
import { privacyService } from '../../../_services'
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import { ContentState, convertFromHTML } from 'draft-js'
import { useDispatch } from 'react-redux';
import { alertActions } from '../../../_actions/alert.actions'
import { useHistory } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link } from "react-router-dom";
import message from '../../_common/Message/message';



const PrivacyPolicy = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(() => { }
    );
    const [editCheck, setEditCheck] = useState(false);
    const [data, setData] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    let type = "privacy";
    useEffect(() => {
        privacyService.getData(type)
            .then((data) => {
                //EditorState.createWithContent(ContentState.createFromText(data.data[0].content))
                //console.log(data.data[0].content)
                setData(data.data)
                setEditorState(EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data.data[0].content)
                    )
                ))
            })


    }, []);

    function handleSubmit(e) {
        setSubmitted(true);
        e.preventDefault();
        if (stateToHTML(editorState.getCurrentContent()) == "<p><br></p>") {
            console.log(submitted)
            setEditCheck(true);
            return false;
        }
        else {
            setEditCheck(false);
        }

        console.log(stateToHTML(editorState.getCurrentContent()))
        setSubmitted(true);

        if (stateToHTML(editorState.getCurrentContent())) {
            confirmAlert({
                title: message.title,
                message: message.content.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            privacyService.add(stateToHTML(editorState.getCurrentContent()))

                                .then((data) => {
                                    if (data.status.code === 0) {
                                        //alert(data.status.message)
                                        setSubmitted(false);
                                        history.push("/privacy-preview");
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }
                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });

        }
    }

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="PRIVACY POLICY" />
                <div className="editor_main flt">
                    <form className={(submitted && editCheck ? ' is-invalid' : '')} >
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            name="edit" toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'/*, 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'indent', 'outdent'*/, 'history'],
                                inline: {
                                    strikethrough: { className: 'hide-toolbar' },
                                    monospace: { className: 'hide-toolbar' },
                                    superscript: { className: 'hide-toolbar' },
                                    subscript: { className: 'hide-toolbar' },
                                },
                            }} />
                        {submitted && editCheck &&
                            <div className="invalid-feedback">Privacy policy is required</div>
                        }
                        <div className="policy_btn flt">
                            <button type="button" className="editor_btn" onClick={handleSubmit}>
                                Submit
                            </button>
                            <Link to="/privacy-preview" className="editor_cancel">Cancel</Link>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
