import React, { useState } from 'react'
import { history } from '../../../_helpers';
import login_logo from '../../../assets/images/login_logo.png';

function PageHead(props) {
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };

    function logout() {
        history.push('/login');
        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
    }
    var adminName = ''

    if (localStorage.hasOwnProperty('user')) {
        adminName = JSON.parse(localStorage.getItem('user'))
        adminName = adminName.firstName;

    }

    return (
        <div className="page_head flt">
            <div className="page_title">{props.title}</div>
            <div className="filler"></div>
            <div className="profile_icon" onClick={handleToggle}>
                <img src={login_logo} alt="admin-logo" />
                <div className={isActive ? "app" : 'app2'}>
                    <button onClick={logout}>Logout</button>
                </div>
            </div>
            <div className="profile_name">{adminName}</div>
        </div>
    )
}

export default PageHead
