import React, { useState, useEffect } from 'react';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { invoiceService } from '../../../_services';
import close from '../../../assets/images/close.svg'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../../_actions/alert.actions';
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css
import { Link } from 'react-router-dom';
import message from '../../_common/Message/message';

function Dashboard() {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState({ remark: '' })
    const [invoicePop, setInvoicePop] = useState(false);
    const [current, setcurrent] = useState(1);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        invoiceService.getData(current)
            .then((data) => setData(data))
    }, [current]);
    const changeCurrentPage = (page) => {
        setcurrent(page)
    }

    const showPop = (id) => {
        setInvoicePop(true);
        invoiceService.getEdit(id)
            .then((data) => {
                if (data.status.code === 0) {
                    setEdit({
                        remark: data.data && data.data.sales && data.data.sales.remark ? data.data.sales.remark : '',
                        id: id
                    });
                }
            })
    }
    const closePop = () => {
        setInvoicePop(false);
        setSubmitted(false);
    }
    function changeHandler(e) {
        const { name, value } = e.target;
        setEdit(edit => ({ ...edit, [name]: value }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (edit.remark.trim()) {
            confirmAlert({
                title: message.title,
                message: message.content.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            invoiceService.edit(edit)
                                .then((data) => {
                                    console.log(edit);
                                    if (data.status.code === 0) {
                                        setEdit({
                                            remark: ''
                                        });
                                        invoiceService.getData()
                                            .then((data) => setData(data))
                                        setInvoicePop(false);
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }
                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });
        }
    }

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container flt">
                <PageHead title="Invoice" />
                <div className="sales_main flt">
                    <div className="sales_table flt">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th width="60px">Sl. No</th>
                                    <th>Reference</th>
                                    <th>Remark</th>
                                    <th>Billed On</th>
                                    <th>Invoice Month</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data && data.data.docs != null ? data.data.docs.map((list, index) =>
                                    <tr key={list._id}>
                                        <td>{10 * (data.data.page - 1) + index + 1}.</td>
                                        <td>{list.billId}</td>
                                        <td>{list.remark}</td>
                                        <td>{list.billed_on}</td>
                                        <td>{list.month_year}</td>
                                        <td>{list.amount}</td>
                                        <td>{list.status == 1 ? <div className="paid_green">Paid</div> : <button className="pay_btn" onClick={() => showPop(list._id)}>Pay</button>}</td>
                                        <td><Link to={"/invoice-details/" + list.billId}><i className="far fa-eye"></i></Link></td>
                                    </tr>) : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination flt">
                        <Pagination
                            currentPage={current}
                            totalSize={data.data && data.data.totalPages * data.data.limit}
                            showFirstLastPages={true}
                            changeCurrentPage={changeCurrentPage}
                            numberOfPagesNextToActivePage={5}
                            theme="bootstrap"
                        />
                    </div>
                    {invoicePop &&
                        <div className="invoice_pop">
                            <div className="invoice_pop_box">
                                <button className="close_btn" onClick={closePop}>
                                    <img src={close} alt="white-rixce" />
                                </button>

                                <div className="invoice_pop_head flt">Add Remark</div>
                                <div className="invoice_pop_cont flt">
                                    <form className="flt">
                                        <div className="invoice_area flt">
                                            <textarea name="remark" value={edit.remark} onChange={changeHandler} className={submitted && !edit.remark.trim() ? ' is-invalid' : ''} ></textarea>
                                            {submitted && !edit.remark.trim() &&
                                                <div className="invalid-feedback">Amount is required</div>
                                            }
                                        </div>
                                        <button type="submit" className="pop_submit" onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Dashboard
