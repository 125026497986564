import { authHeader } from '../_helpers';
import axios from 'axios'

export const invoiceService = {
    getData,
    getEdit,
    edit
};


async function getData(pno) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}invoice?page=${pno}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function getEdit(id) {
    console.log(id)
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}invoice/${id}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function edit(edit) {
    var bodyFormData = new FormData();
    bodyFormData.append("remark",edit.remark)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}invoice/`+edit.id,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);

}

function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}