import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import sidebar_logo from '../../../assets/images/sidebar_logo.png';
import menu_dashboard from '../../../assets/images/menu_dashboard.png';
import menu_add from '../../../assets/images/menu_add.png';
import menu_merchant from '../../../assets/images/menu_merchant.png';
import menu_plans from '../../../assets/images/menu_plans.png';
import menu_contact from '../../../assets/images/menu_contact.png';
import menu_privacy from '../../../assets/images/menu_privacy.png';
import menu_terms from '../../../assets/images/menu_terms.png';
import menu_invoice from '../../../assets/images/menu_invoice.png';
import menu_setting from '../../../assets/images/menu_setting.png';
import version from '../../../assets/images/version.png';

function Sidebar() {

    var location = useLocation().pathname;

    return (
        <div className="sidebar">
            <div className="sidebar_logo flt">
                <img src={sidebar_logo} alt="logo" />
            </div>
            <OverlayScrollbarsComponent options={{ scrollbars: { autoHide: 'scroll' } }} className="sidebar_nav flt">
                <ul>
                    <li>
                        <Link to="/dashboard" className={location === '/dashboard' ? "active" : ''}><span className="nav_icon"><img src={menu_dashboard} alt="menu-icon"/></span><span className="nav_txt">DASHBOARD</span></Link>
                    </li>
                    <li>
                        <Link to="/advertisement" className={location === '/advertisement' ? "active" : ''}><span className="nav_icon"><img src={menu_add} alt="menu-icon"/></span><span className="nav_txt">ADVERTISEMENT</span></Link>
                    </li>
                    <li>
                        <Link to="/merchant" className={location === '/merchant' ? "active" : ''}><span className="nav_icon"><img src={menu_merchant} alt="menu-icon"/></span><span className="nav_txt">MERCHANT</span></Link>
                    </li>
                    <li>
                        <Link to="/plans" className={location === '/plans' ? "active" : ''}><span className="nav_icon"><img src={menu_plans} alt="menu-icon"/></span><span className="nav_txt">PLANS</span></Link>
                    </li>
                    <li>
                        <Link to="/contact" className={location === '/contact' ? "active" : ''}><span className="nav_icon"><img src={menu_contact} alt="menu-icon"/></span><span className="nav_txt">CONTACT</span></Link>
                    </li>
                    <li>
                        <Link to="/privacy-preview" className={(location === '/privacy-policy' || location === '/privacy-preview') ? "active" : ''}><span className="nav_icon"><img src={menu_privacy} alt="menu-icon"/></span><span className="nav_txt">PRIVACY POLICY</span></Link>
                    </li>
                    <li>
                        <Link to="/terms-conditions-preview" className={(location === '/terms-conditions' || location === '/terms-conditions-preview') ? "active" : ''}><span className="nav_icon"><img src={menu_terms} alt="menu-icon"/></span><span className="nav_txt">T&C</span></Link>
                    </li>
                    <li>
                        <Link to="/invoice" className={(location === '/invoice' || location === '/invoice-details/:invoice_id') ? "active" : ''}><span className="nav_icon"><img src={menu_invoice} alt="menu-icon"/></span><span className="nav_txt">INVOICE</span></Link>
                    </li>
                    <li>
                        <Link to="/setting" className={location === '/setting' ? "active" : ''}><span className="nav_icon"><img src={menu_setting} alt="menu-icon"/></span><span className="nav_txt">SETTING</span></Link>
                    </li>
                </ul>
                <div className="version">
                    <img src={version} alt="version" /><br />
                    V.1.0.0
                </div>
            </OverlayScrollbarsComponent>
        </div>
    )
}

export default Sidebar
