import React, { useState, useEffect } from 'react';
import Pagination from "react-pagination-js";
import { merchantService } from '../../../_services';
import { confirmAlert } from 'react-confirm-alert';
import Sidebar from '../../_common/Sidebar/Sidebar';
import PageHead from '../../_common/PageHead/PageHead';
import profile_image from '../../../assets/images/profile_image.png';
import profile_edit from '../../../assets/images/profile_edit.png';
import { alertActions } from '../../../_actions/alert.actions';
import { useDispatch } from 'react-redux';
import message from '../../_common/Message/message';
import Loader from "react-loader-spinner";

const Merchant = () => {
    const dispatch = useDispatch();
    const profile_url = process.env.REACT_APP_MEDIA_DOMAIN; // profile image url
    const [numberValid, setNumberValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [validFile, setValidFile] = useState(true); // file type must be image only
    const [data, setData] = useState([]);
    const [current, setcurrent] = useState(1);
    const [planData, setPlanData] = useState([]);
    const [title, setTitle] = useState();
    const [limitFile, setLimitFile] = useState(true); // file size limit 10 Mb
    const [loader, setLoader] = useState(false); // loader
    useEffect(() => {
        setLoader(true);
        merchantService.getData(current, '')
            .then((data) => {
                setData(data)
                setLoader(false)
            }
            )
    }, [current])
    const changeCurrentPage = (page) => {
        setcurrent(page)
    }
    useEffect(() => {
        merchantService.getPlan(1)
            .then((planData) => setPlanData(planData))
    }, [])

    const searchHandler = (e) => {
        merchantService.getData('', e.target.value)
            .then((data) => setData(data))
    }

    const [add, setAdd] = useState({
        company_name: '',
        profile: '',
        firstName: '',
        telephone_number: '',
        email: '',
        password: '',
        title: ''
    });

    function changeHandler(e) {
        console.log(e)
        const { name, value } = e.target;
        setAdd(add => ({ ...add, [name]: value }));
        if (e.target.name == 'title') {
            setTitle(e.target.value);
        }



        if (e.target.name == 'email') {
            var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!filter.test(e.target.value)) {
                setEmailValid(false)
            } else {
                setEmailValid(true)
            }
        } // Email validation

        if (e.target.name == 'telephone_number') {
            var filter = /^[0-9]*$/;
            if (!filter.test(e.target.value)) {
                setNumberValid(false)
            } else {
                setNumberValid(true)
            }
        } // number validation

        if (e.target.name == 'password') {
            if ((e.target.value).length < 6) {
                setPasswordValid(false)
            } else {
                setPasswordValid(true)
            }
        } // number validation
    }

    const [selectedFile, setSelectedFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();

    const changeFileHandler = (e) => {
        if (e.target.files.length) {
            if (e.target.files[0].size > 1000000 * 10) {
                setLimitFile(false);
                e.target.files = null;
                return false;
            }
            else {
                setLimitFile(true);
                setSelectedFile(e.target.files[0]);
                setImagePreviewUrl(null)
                e.preventDefault();
                let reader = new FileReader();
                let file = e.target.files[0];
                if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg') ) {
                    reader.onloadend = () => {
                        setImagePreviewUrl(reader.result);
                        setValidFile(true)
                    }
                }
                else {
                    setValidFile(false)
                }
                reader.readAsDataURL(file);
            }

        }
    };

    const [submitted, setSubmitted] = useState(false)
    function handleSubmit(e) {
        console.log(add)
        e.preventDefault();
        setSubmitted(true);
        var x = 0;
        if (add.company_name.trim() && add.firstName.trim() && add.telephone_number && add.email && add.password.trim() && add.title && selectedFile && numberValid && emailValid && passwordValid && validFile && limitFile) {
            formValues.forEach(signval => {
                if (!signval.signagename.trim() || !signval.floor.trim() || !signval.ratio) {
                    x++;
                }
            });
            if (!x) {
                confirmAlert({
                    title: message.title,
                    message: message.content.add,
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => {
                                merchantService.add(add, selectedFile)
                                    .then((data) => {
                                        if (data.status.code === 0) {
                                            merchantService.addFloor(formValues, data.data._id)
                                                .then((data) => {
                                                    if (data.status.code === 0) {
                                                        setFormValues([{ signagename: '', floor: '', ratio: '' }])
                                                    }
                                                })

                                            setAdd({
                                                id: '',
                                                company_name: '',
                                                profile: '',
                                                firstName: '',
                                                telephone_number: '',
                                                email: '',
                                                password: ''
                                            });
                                            setTitle('');

                                            merchantService.getData('', '')
                                                .then((data) => setData(data))
                                            setSelectedFile(null);
                                            setImagePreviewUrl(null)
                                            setSubmitted(false)
                                            dispatch(alertActions.success(data.status.message));
                                            setTimeout(
                                                () => (dispatch(alertActions.clear())),
                                                2000
                                            );

                                        }
                                        else {
                                            dispatch(alertActions.error(data.data[0].msg));
                                            setTimeout(
                                                () => (dispatch(alertActions.clear())),
                                                2000
                                            );
                                        }

                                    })
                            }
                        },
                        {
                            label: 'No',
                            onClick: () => null

                        }
                    ]
                });
            }
        }
    }

    const handleEdit = (id) => {
        setImagePreviewUrl(null);
        setLimitFile(true);
        merchantService.getEdit(id)
            .then((data) => {
                console.log(add);
                if (data.status.code === 0) {
                    setAdd({
                        company_name: data.data && data.data.company_name ? data.data.company_name : '',
                        firstName: data.data && data.data.firstName ? data.data.firstName : '',
                        telephone_number: data.data && data.data.telephone_number ? data.data.telephone_number : '',
                        email: data.data && data.data.email ? data.data.email : '',
                        password: '******',
                        id: id
                    });
                    console.log(add)
                    changeHandler({
                        target: {
                            name: 'email',
                            value: data.data.email
                        }
                    });
                    changeHandler({
                        target: {
                            name: 'telephone_number',
                            value: data.data.telephone_number
                        }
                    });
                    changeHandler({
                        target: {
                            name: 'password',
                            value: '******'
                        }
                    });
                    setFormValues(data.data.signage);
                    setTitle(data.data.plan && data.data.plan._id ? data.data.plan._id : '');

                    setImagePreviewUrl(data.data && data.data.profile ? profile_url + data.data.profile : '')
                    setIsOpened(true);
                }
            })

    }

    function submitEdit(e) {
        setSubmitted(true);
        setSelectedFile(true);
        var x = 0;
        if (add.company_name.trim() && add.firstName.trim() && add.telephone_number && add.email && add.password.trim() && title && numberValid && emailValid && passwordValid && validFile && limitFile) {
            formValues.forEach(signval => {
                if (!signval.signagename.trim() || !signval.floor.trim() || !signval.ratio) {
                    x++;
                }
            });
            if (!x) {
                confirmAlert({
                    title: message.title,
                    message: message.content.update,
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => {
                                e.preventDefault();
                                merchantService.edit(add, selectedFile, title)
                                    .then((data) => {
                                        if (data.status.code === 0) {
                                            merchantService.editFloor(formValues, data.data.id)
                                                .then((data) => {
                                                    if (data.status.code === 0) {
                                                        setFormValues([{ signagename: '', floor: '', ratio: '' }])
                                                    }
                                                });
                                            setAdd({
                                                company_name: '',
                                                profile: '',
                                                firstName: '',
                                                telephone_number: '',
                                                email: '',
                                                password: ''
                                            });
                                            setTitle('');
                                            merchantService.getData('', '')
                                                .then((data) => setData(data));
                                            setSelectedFile(null);
                                            setSubmitted(false);
                                            setImagePreviewUrl(null)
                                            setIsOpened(wasOpened => !wasOpened);
                                            setcurrent(1);
                                            dispatch(alertActions.success(data.status.message));
                                            setTimeout(
                                                () => (dispatch(alertActions.clear())),
                                                2000
                                            );
                                        }
                                    })
                            }
                        },
                        {
                            label: 'No',
                            onClick: () => null
                        }
                    ]
                });
            }
            
        }
    }

    function handleCancel(e) {
        e.preventDefault();
        setAdd({
            company_name: '',
            profile: '',
            firstName: '',
            telephone_number: '',
            email: '',
            password: '',
        });
        setTitle('');
        setFormValues([{ signagename: '', floor: '', ratio: '' }])
        setIsOpened(wasOpened => !wasOpened);
        setSelectedFile(null);
        setImagePreviewUrl(null);
        setSubmitted(false);
    }

    const handleDelete = (id) => {
        confirmAlert({
            title: message.title,
            message: message.content.delete,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => merchantService.delete(id)
                        .then((data) => {
                            if (data.status.code === 0) {
                                merchantService.getData('', '')
                                    .then((data) => setData(data))
                                setcurrent(1);
                                dispatch(alertActions.success(data.status.message));
                                setTimeout(
                                    () => (dispatch(alertActions.clear())),
                                    2000
                                );
                            }
                        })
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        });
    }


    const [isOpened, setIsOpened] = useState(false);//add and edit button toggle

    // initialize signage name, floor, resolution
    const [formValues, setFormValues] = useState([{ signagename: '', floor: '', ratio: '' }])

    // handle change for signage name, floor, resolution
    const handleFloorChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }
    // add more signage name, floor, resolution
    const moreFloor = () => {
        setFormValues([...formValues, { signagename: '', floor: '', ratio: '' }])
    }

    // delete signage name, floor, resolution
    const deleteFloor = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="MERCHANT" />
                <div className="adv_main merchant_mod flt">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="adv_left flt">
                                <div className="profile_image flt">
                                    <span>
                                        {!selectedFile && !imagePreviewUrl &&
                                            <img src={profile_image} alt="profile_image" />
                                        }
                                        {imagePreviewUrl &&
                                            <img src={imagePreviewUrl} alt="profile_image" className="preview_image" />
                                        }
                                        <div className="profile_upload">
                                            <img src={profile_edit} alt="profile_edit" />
                                            <input type="file" accept="image/*" name="profile" onChange={changeFileHandler} className={(submitted && !selectedFile ? ' is-invalid' : '')} />
                                            {submitted && !imagePreviewUrl && validFile && limitFile &&
                                                <div className="invalid-feedback profile_empty">Profile is required</div>
                                            }
                                            {!validFile &&
                                                <div className="invalid-feedback profile_invalid">File type must be image only</div>
                                            }
                                            {validFile && !limitFile &&
                                                <div className="invalid-feedback profile_large">File size must be less than 10 Mb</div>
                                            }
                                        </div>
                                    </span>
                                </div>
                                <form className="add_mer flt">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input type="text" name="company_name" value={add.company_name} onChange={changeHandler} className={submitted && !add.company_name.trim() ? ' is-invalid' : ''} />
                                        {submitted && !add.company_name.trim() &&
                                            <div className="invalid-feedback">Company name is required</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" name="firstName" value={add.firstName} onChange={changeHandler} className={submitted && !add.firstName.trim() ? ' is-invalid' : ''} />
                                        {submitted && !add.firstName.trim() &&
                                            <div className="invalid-feedback">Name is required</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label>Number</label>
                                        <input type="text" name="telephone_number" value={add.telephone_number} onChange={changeHandler} className={(submitted && !add.telephone_number) || (!numberValid && add.telephone_number) ? ' is-invalid' : ''} />
                                        {submitted && !add.telephone_number &&
                                            <div className="invalid-feedback">Number is required</div>
                                        }
                                        {!numberValid && add.telephone_number &&
                                            <div className="invalid-feedback">Please enter valid number</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label>Plan</label>
                                        <select onChange={changeHandler} name="title" value={title} className={submitted && !title ? ' is-invalid' : ''} >
                                            <option value='' key='' selected disabled>Please select</option>
                                            {planData.data && planData.data.plan && planData.data.plan != null ? planData.data.plan.map((option) => (
                                                <option value={option._id} key={option._id}>{option.title}</option>
                                            )) : null}
                                        </select>
                                        {submitted && !title &&
                                            <div className="invalid-feedback">Plan is required</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" value={add.email} onChange={changeHandler} className={(submitted && !add.email) || (!emailValid && add.email) ? ' is-invalid' : ''} />
                                        {submitted && !add.email &&
                                            <div className="invalid-feedback">Email ID is required</div>
                                        }
                                        {!emailValid && add.email &&
                                            <div className="invalid-feedback">Please enter valid email ID</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" name="password" value={add.password} onChange={changeHandler} className={(submitted && !add.password.trim()) || (!passwordValid && add.password.trim()) ? ' is-invalid' : ''} />
                                        {submitted && !add.password.trim() &&
                                            <div className="invalid-feedback">Password is required</div>
                                        }
                                        {!passwordValid && add.password.trim() &&
                                            <div className="invalid-feedback">Password must be at least 6 characters</div>
                                        }
                                    </div>
                                    {formValues.map((element, index) => (
                                        <div className="floor_group flt" key={index}>
                                            <div className="floor_ip">
                                                <div className="form-group">
                                                    <label>Signage name</label>
                                                    <input type="text" name="signagename" value={element.signagename || ""} onChange={e => handleFloorChange(index, e)} className={submitted && !element.signagename.trim() ? ' is-invalid' : ''} />
                                                    {
                                                        submitted && !element.signagename.trim() &&
                                                        <div className="invalid-feedback">Signage name  is required</div>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Floor</label>
                                                    <input type="text" name="floor" value={element.floor || ""} onChange={e => handleFloorChange(index, e)} className={submitted && !element.floor.trim() ? ' is-invalid' : ''} />
                                                    {
                                                        submitted && !element.floor.trim() &&
                                                        <div className="invalid-feedback">Signage floor  is required</div>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Resolution</label>
                                                    <select name="ratio" value={element.ratio || ""} onChange={e => handleFloorChange(index, e)} className={submitted && !element.ratio ? ' is-invalid' : ''}>
                                                        <option value='' selected disabled>Select</option>
                                                        <option>16:9</option>
                                                        <option>9:16</option>
                                                    </select>
                                                    {
                                                        submitted && !element.ratio &&
                                                        <div className="invalid-feedback">Signage ratio  is required</div>
                                                    }
                                                </div>
                                            </div>
                                            {index ?
                                                <div className="floor_delete">
                                                    <i className="fas fa-trash-alt" onClick={() => deleteFloor(index)}></i>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    ))
                                    }


                                    <div className="add_floor flt">
                                        <i className="far fa-plus-square" onClick={moreFloor}></i>
                                    </div>
                                    <div className="adv_btn flt">
                                        {!isOpened && (
                                            <button type="button" onClick={handleSubmit}>Add</button>
                                        )}
                                        {isOpened && (
                                            <button type="button" onClick={submitEdit} >Update</button>
                                        )}
                                        {isOpened && (
                                            <button type="button" onClick={handleCancel} >Cancel</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="adv_right flt">
                                {loader && <Loader
                                    type="Oval"
                                    color="rgb(40 44 52)"
                                    height={100}
                                    width={100}
                                    className="loader"
                                />}
                                {isOpened &&
                                    <div className="adv_overlay"></div>
                                }
                                <div className="search_adv flt">
                                    <input type="seach" placeholder="Search for company or email" onKeyUp={(e) => searchHandler(e)} />
                                </div>
                                <div className="adv_table flt">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Sl. No.</th>
                                                <th>Profile</th>
                                                <th>Company Name</th>
                                                <th>Name</th>
                                                <th>Number</th>
                                                <th>Plan</th>
                                                <th>Email</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.data && data.data.users.docs != null ? data.data.users.docs.map((list, index) =>
                                                < tr key={list._id}>
                                                    <td>{10 * (data.data.users.page - 1) + index + 1}.</td>
                                                    <td><img src={profile_url + list.profile} /></td>
                                                    <td><div className="ellipse_cls">{list.company_name}</div></td>
                                                    <td><div className="ellipse_cls">{list.firstName}</div></td>
                                                    <td>{list.telephone_number}</td>
                                                    <td>{list.plan ? list.plan.title : ''}</td>
                                                    <td>{list.email}</td>
                                                    <td><button type="button" onClick={() => handleEdit(list._id)}><i className="fas fa-pencil-alt"></i></button></td>
                                                    <td><button type="button" onClick={() => handleDelete(list._id)}><i className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            ) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination flt">
                                    <Pagination
                                        currentPage={current}
                                        totalSize={data.data && data.data.users.totalPages * data.data.users.limit}
                                        showFirstLastPages={true}
                                        changeCurrentPage={changeCurrentPage}
                                        numberOfPagesNextToActivePage={5}
                                        theme="bootstrap"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Merchant
