import { authHeader } from '../_helpers';
import axios from 'axios'

export const termsService = {
    getData,
    add
};

async function getData(type) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}static-page?type=${type}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}
async function add(edits) {
    var bodyFormData = new FormData();
    bodyFormData.append('type', 'tc');
    bodyFormData.append('content', edits);
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}static-page`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}