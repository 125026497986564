import React from 'react'
import logo from '../../../assets/images/logo.png';
import wrss from '../../../assets/images/wrss.svg';
import landing_right from '../../../assets/images/landing_right.svg';
import { Link } from "react-router-dom";

const LandingPage = () => {
    return (
        <div className="landing_container flt">
            <div className="header_container flt">
                <img src={logo} alt="Logo" />
            </div>
            <div className="landing_cont flt">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="landing_left flt">
                                <div className="landing_tag flt">Let's create together</div>
                                <div className="landing_desc flt">こんにちは、</div>
                                <div className="landing_title flt">WHITE RICE</div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="landing_middle flt">
                                <img src={wrss} alt="white-rice" />
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="landing_right flt">
                                <img src={landing_right} alt="white-rice" />
                            </div>
                        </div>
                    </div>
                    <div className="landing_login flt">
                        <Link to="/login">Login <i className="far fa-arrow-alt-circle-right"></i></Link>
                    </div>
                </div>
            </div>
            <div className="dill">
                © DILL
            </div>
        </div>
    )
}

export default LandingPage
