import { authHeader } from '../_helpers';
import axios from 'axios'

export const settingService = {
    update
};


async function update(update) {
    var bodyFormData = new FormData();
    bodyFormData.append("email",update.email)
    bodyFormData.append("password",update.password)
    bodyFormData.append("new_password",update.new_password)
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}update-password`,
        headers: authHeader(),
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}