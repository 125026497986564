import React, { useState, useEffect } from 'react';
import Pagination from "react-pagination-js";
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { contactService } from '../../../_services';
import Loader from "react-loader-spinner";

function Contact() {
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState(1);
    const [loader, setLoader] = useState(false); // loader
    useEffect(() => {
        setLoader(true);
        contactService.getData(current, '')
            .then((data) => {
                setData(data);
                setLoader(false);
            })
    }, [current]);
    console.log(current);
    const changeCurrentPage = (page) => {
        setCurrent(page)
    }
    const searchHandler = (e) => {
        console.log(e)
        contactService.getData('', e.target.value)
            .then((data) => setData(data))
    }
    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container flt">
                <PageHead title="Contact" />
                <div className="adv_main flt">
                    <div className="adv_right flt">
                        {loader && <Loader
                            type="Oval"
                            color="rgb(40 44 52)"
                            height={100}
                            width={100}
                            className="loader"
                        />}
                        <div className="search_adv flt">
                            <input type="seach" placeholder="Search for name or email" onKeyUp={(e) => searchHandler(e)} />
                        </div>
                        <div className="adv_table flt">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sl.No.</th>
                                        <th>Name</th>
                                        <th>Furigana</th>
                                        <th>Email</th>
                                        <th>Genre</th>
                                        <th>Content</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.data && data.data.docs != null ? data.data.docs.map((list, index) =>
                                        <tr key={list._id}>
                                            <td>{10 * (data.data.page - 1) + index + 1}.</td>
                                            <td>{list.name}</td>
                                            <td>{list.furigana}</td>
                                            <td>{list.email}</td>
                                            <td>{list.genre}</td>
                                            <td>{list.content}</td>
                                        </tr>
                                    ) : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination flt">
                            <Pagination
                                currentPage={current}
                                totalSize={data.data && data.data.totalPages * data.data.limit}
                                showFirstLastPages={true}
                                changeCurrentPage={changeCurrentPage}
                                numberOfPagesNextToActivePage={5}
                                theme="bootstrap"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
