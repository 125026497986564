import { authHeader } from '../_helpers';
import axios from 'axios'


export const userService = {
    login,
    logout
};

async function login(email, password) {
    var bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('password', password);
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}login`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
    };

    return await axios(
        requestOptions
    )
        .then(handleResponse)
        .then(data => {
            console.log(data)
            if (data.status.code == 0) {
                console.log('data')
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('access_token', JSON.stringify(data.data.access_token));
                localStorage.setItem('user', JSON.stringify(data.data.user));
                return data;
            }
            else {
                return data; 
            }

        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
}


function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}