import { authHeader } from '../_helpers';
import axios from 'axios'

export const merchantService = {
    getData,
    add,
    edit,
    getEdit,
    delete: _delete,
    addFloor,
    editFloor,
    getPlan
};


async function getData(pno, search) {
    console.log(search)
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant?page=${pno}`+'&search='+search,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function add(add,profile) {
    var bodyFormData = new FormData();
    bodyFormData.append("company_name",add.company_name)
    bodyFormData.append("profile",profile)
    bodyFormData.append("firstName",add.firstName)
    bodyFormData.append("telephone_number",add.telephone_number)
    bodyFormData.append("plan_id",add.title)
    bodyFormData.append("email",add.email)
    bodyFormData.append("password",add.password)
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant`,
        headers: authHeader(),
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function edit(add,profile, title) {
    console.log(add.id)
    var bodyFormData = new FormData();
    bodyFormData.append("company_name",add.company_name)
    if (profile){
        bodyFormData.append("profile",profile)
    }
    bodyFormData.append("firstName",add.firstName)
    bodyFormData.append("telephone_number",add.telephone_number)
    bodyFormData.append("plan_id",title)
    bodyFormData.append("email",add.email)
    if (add.password!=='******') {
    bodyFormData.append("password",add.password)
    }
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/`+add.id,
        headers: authHeader(),
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
    
}
async function getEdit(id) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/${id}`,
        headers: authHeader()
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    console.log(id);
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_BASE_URL}merchant/${id}`,
        headers: authHeader()
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}



async function addFloor(addFloor, id) {
    console.log(addFloor)
    // var bodyFormData = new FormData();

    // bodyFormData.append("signagename",addFloor.signagename)
    // bodyFormData.append("floor",addFloor.floor)
    // bodyFormData.append("ratio",addFloor.ratio)
    var bodyFormData = {
        item:addFloor
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        url: `${process.env.REACT_APP_BASE_URL}floor/${id}`,
       
        data: bodyFormData
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function editFloor(addFloor, id) {
    var bodyFormData = {
        item:addFloor
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        url: `${process.env.REACT_APP_BASE_URL}floor/${id}`,
       
        data: bodyFormData
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function getPlan(paginate=0) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}plan?&paginate=`+paginate,
        headers: authHeader()
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}