import { authHeader } from '../_helpers';
import axios from 'axios'

export const plansService = {
    getData,
    add,
    edit,
    getEdit,
    delete: _delete
};

async function getData(pno) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}plan?page=${pno}`,
        headers: authHeader()
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function add(add, formValues) {
    console.log(add)
    var bodyFormData = new FormData();
    // bodyFormData.append("title",add.title)
    // bodyFormData.append("amount",add.amount)
    // bodyFormData.append("service[]",formValues.service)
    bodyFormData = {
        title:add.title,
        amount:add.amount,
        service:formValues,
        support_mobile:add.support_mobile,
        support_email:add.support_email
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        url: `${process.env.REACT_APP_BASE_URL}plan`,
        data: bodyFormData
    };


    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function edit(add, formValues) {
    console.log(add.id)
    var bodyFormData = new FormData();
    bodyFormData = {
        title:add.title,
        amount:add.amount,
        service:formValues,
        support_mobile:add.support_mobile,
        support_email:add.support_email
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        url: `${process.env.REACT_APP_BASE_URL}plan/`+add.id,
        data: bodyFormData
    };

    return await axios(
        requestOptions
    ).then(handleResponse);

}


async function getEdit(id) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}plan/${id}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    console.log(id);
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_BASE_URL}plan/${id}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);

}






function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}