import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import 'react-dropzone-uploader/dist/styles.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


ReactDOM.render(

  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
