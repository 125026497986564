import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import './assets/css/style.css';
import { history } from './_helpers';
import { alertActions } from './_actions';
import { PrivateRoute } from './_helpers';
import { useDispatch, useSelector } from 'react-redux';
import LandingPage from './components/pages/LandingPage/LandingPage';
import Login from './components/pages/LoginPage/Login';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './components/pages/TermsConditions/TermsConditions';
import TermsCondtionPreview from './components/pages/TermsConditions/TermsCondtionPreview';
import Advertisement from './components/pages/Advertisement/Advertisement';
import PrivacyPreview from './components/pages/PrivacyPolicy/PrivacyPreview';
import Merchant from './components/pages/MerchantPage/Merchant';
import Plans from './components/pages/PlansPage/Plans';
import Contact from './components/pages/ContactPage/Contact';
import Dashboard from './components/pages/Dashboad/Dashboard';
import Invoice from './components/pages/InvoicePage/Invoice';
import InvoiceDetails from './components/pages/InvoiceDetails/InvoiceDetails';
import Setting from './components/pages/SettingPage/Setting';




function App() {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    require('dotenv').config()

    return (
        <div>

            {alert.message && 
                <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
           

            <Router history={history}>
                <Switch>
                    <PrivateRoute path='/dashboard' component={Dashboard} />
                    <PrivateRoute path='/advertisement' component={Advertisement} />
                    <PrivateRoute path='/privacy-policy' component={PrivacyPolicy} />
                    <PrivateRoute path='/privacy-preview' component={PrivacyPreview} />
                    <PrivateRoute path='/terms-conditions' component={TermsConditions} />
                    <PrivateRoute path='/terms-conditions-preview' component={TermsCondtionPreview} />
                    <PrivateRoute path='/merchant' component={Merchant} />
                    <PrivateRoute path='/plans' component={Plans} />
                    <PrivateRoute path='/contact' component={Contact}/>
                    <PrivateRoute path='/invoice' component={Invoice} />
                    <PrivateRoute path='/invoice-details/:invoice_id' component={InvoiceDetails} />
                    <PrivateRoute path='/setting' component={Setting} />
                    <Route path='/login' component={Login} />
                    <Route path='/' component={LandingPage} />

                    <Redirect from="*" to="/" />
                </Switch>
            </Router>
        </div>


    );
}

export default App;
