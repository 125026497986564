import React, { useState, useEffect } from 'react'
import { advertisementService } from '../../../_services'
import PageHead from '../../_common/PageHead/PageHead'
import Sidebar from '../../_common/Sidebar/Sidebar'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css
import ModernDatepicker from 'react-modern-datepicker';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../../_actions/alert.actions';
import message from '../../_common/Message/message';
import Loader from "react-loader-spinner";

const Advertisement = () => {
    const adv_file = process.env.REACT_APP_MEDIA_DOMAIN; // profile image url
    const dispatch = useDispatch();
    const ref = React.useRef();
    const [numberValid, setNumberValid] = useState(true);
    const [data, setData] = useState([]);
    const [current, setcurrent] = useState(1);
    const [isOpened, setIsOpened] = useState(false); //add and edit button toggle
    const [fromdate, setfrom] = useState();
    const [todate, setto] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [videoPreviewUrl, setVideoPreviewUrl] = useState();
    const [isImageUrl, setIsImageUrl] = useState(false);
    const [validFile, setValidFile] = useState(true); // file type either image or video only
    const [limitFile, setLimitFile] = useState(true); // file size limit below 50 Mb
    const [loader, setLoader] = useState(false); // loader



    useEffect(() => {
        setLoader(true);
        advertisementService.getData(current, '')
            .then((data) => {
                setData(data)
                setLoader(false)
            })
        console.log(current)


    }, [current])
    const changeCurrentPage = (page) => {
        setcurrent(page)
    }

    const searchHandler = (e) => {
        advertisementService.getData('', e.target.value)
            .then((data) => setData(data))
    }
    // const getpagedata = (option) => {
    //     if (option === 0) {
    //         setPage(page - 1);
    //         advertisementService.getData(page)
    //             .then((data) => setData(data))
    //     } else if (option === 1) {

    //         setPage(page + 1);
    //         advertisementService.getData(page)
    //             .then((data) => setData(data))
    //         console.log(page)
    //     }
    // }
    const handleEdit = (id) => {
        setImagePreviewUrl(null);
        setVideoPreviewUrl(null);
        setSelectedFile(null);
        setLimitFile(true);
        advertisementService.getEdit(id)
            .then((data) => {
                if (data.status.code === 0) {
                    setAdd({
                        ads_info: data.data && data.data.ads_info ? data.data.ads_info : '',
                        amount: data.data && data.data.amount ? data.data.amount : '',
                        from: data.data && data.data.from ? data.data.from : '',
                        to: data.data && data.data.to ? data.data.to : '',
                        id: data.data && data.data._id ? data.data._id : ''
                    });

                    changeHandler({
                        target: {
                            name: 'amount',
                            value: data.data.amount
                        }
                    });
                    setIsOpened(true);

                    var url = adv_file + data.data.upload_file
                    console.log(url)
                    if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                        setIsImageUrl(true);
                        setImagePreviewUrl(adv_file + data.data.upload_file);
                    } else {
                        setIsImageUrl(false);
                        setVideoPreviewUrl(adv_file + data.data.upload_file);
                    }
                }
            })
    }
    function submitEdit(e) {
        setSubmitted(true);
        setSelectedFile(true);
        if (add.ads_info.trim() && add.amount && add.from && add.to && numberValid && validFile && limitFile) {

            confirmAlert({
                title: message.title,
                message: message.content.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            e.preventDefault();
                            advertisementService.edit(add, selectedFile)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        setAdd({
                                            ads_info: '',
                                            amount: '',
                                            from: '',
                                            to: '',
                                            id: ''
                                        });
                                        ref.current.value = "";
                                        setSelectedFile(null);
                                        setImagePreviewUrl(null)
                                        setVideoPreviewUrl(null)
                                        advertisementService.getData('', '')
                                            .then((data) => setData(data))
                                        //alert(data.status.message)
                                        setIsOpened(wasOpened => !wasOpened);
                                        setcurrent(1);
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }
                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });
            setSubmitted(false)
        }

    }
    const handleDelete = (id) => {
        confirmAlert({
            title: message.title,
            message: message.content.delete,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => advertisementService.delete(id)
                        .then((data) => {
                            if (data.status.code === 0) {
                                advertisementService.getData('', '')
                                    .then((data) => setData(data))
                                setcurrent(1);
                                dispatch(alertActions.success(data.status.message));
                                setTimeout(
                                    () => (dispatch(alertActions.clear())),
                                    2000
                                );
                            }
                        })
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        });
    }


    const [add, setAdd] = useState({
        ads_info: '',
        amount: '',
        from: '',
        to: '',
        id: ''
    });

    function changeHandler(e) {
        const { name, value } = e.target;
        setAdd(add => ({ ...add, [name]: value }));
        console.log(value);
        if (e.target.name == 'amount') {
            var filter = /^[0-9]*$/;
            if (!filter.test(e.target.value)) {
                setNumberValid(false)
            } else {
                setNumberValid(true)
            }
        } // number validation
    }
    function fromchangeHandler(e) {
        add.from = e;
        add.to = e;
        add.to = null;
        setfrom(e);
    }
    function tochangeHandler(e) {
        add.to = null;
        add.to = e;
        setto(add.to)
    }
    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (add.ads_info.trim() && add.amount && add.from && add.to && selectedFile && numberValid && validFile && limitFile) {

            confirmAlert({
                title: message.title,
                message: message.content.add,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            advertisementService.add(add, selectedFile)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        setAdd({
                                            ads_info: '',
                                            amount: '',
                                            from: '',
                                            to: '',
                                            id: ''
                                        });
                                        ref.current.value = "";
                                        setSelectedFile(null);
                                        setImagePreviewUrl(null);
                                        setVideoPreviewUrl(null);
                                        advertisementService.getData('', '')
                                            .then((data) => setData(data))
                                        // alert(data.status.message)
                                        setSubmitted(false);
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );

                                    }
                                })

                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });
        }
    }

    function handleCancel(e) {
        e.preventDefault();
        setAdd({
            ads_info: '',
            amount: '',
            from: '',
            to: '',
            id: ''
        });
        ref.current.value = "";
        setIsOpened(wasOpened => !wasOpened);
        setImagePreviewUrl(null);
        setVideoPreviewUrl(null);
        setSubmitted(false);
    }
    const [submitted, setSubmitted] = useState(false);




    const changeFileHandler = (e) => {
        if (e.target.files.length) {
            setImagePreviewUrl(null);
            setVideoPreviewUrl(null);
            setSelectedFile(null);

            if (e.target.files[0].size > 1000000 * 50) {
                setLimitFile(false);
                e.target.files = null;
                return false;
            }
            else {
                setLimitFile(true);
                setSelectedFile(e.target.files[0]);
                setImagePreviewUrl(null);
                e.preventDefault();
                let reader = new FileReader();
                let file = e.target.files[0];
                if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg') ) {
                    setIsImageUrl(true);
                    reader.onloadend = () => {
                        setImagePreviewUrl(reader.result)
                        setValidFile(true)
                    }
                } else if (file.type.match('video/mp4')) {
                    setIsImageUrl(false);
                    setVideoPreviewUrl(null);
                    reader.onloadend = () => {
                        setVideoPreviewUrl(reader.result)
                    }
                    setValidFile(true)
                }
                else {
                    setValidFile(false)
                }
                reader.readAsDataURL(file);

                e.target.files = null;

            }
        }

    }


    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container flt">
                <PageHead title="Advertisement" />
                <div className="adv_main flt">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="adv_left flt">
                                <form className="add_adv flt">
                                    <div className="form-group flt">
                                        <label>Store Name</label>
                                        <input type="text" name="ads_info" value={add.ads_info} onChange={changeHandler} className={(submitted && !add.ads_info.trim() ? ' is-invalid' : '')} />
                                        {submitted && !add.ads_info.trim() &&
                                            <div className="invalid-feedback">Store name is required</div>
                                        }
                                    </div>
                                    <div className="form-group flt">
                                        <label>Amount/Day</label>
                                        <input type="text" name="amount" value={add.amount} onChange={changeHandler} className={(submitted && !add.amount) || (!numberValid && add.amount) ? ' is-invalid' : ''} />
                                        {submitted && !add.amount &&
                                            <div className="invalid-feedback">Amount is required</div>
                                        }
                                        {!numberValid && add.amount &&
                                            <div className="invalid-feedback">Please enter valid Amount</div>
                                        }
                                    </div>
                                    <div className="form-group flt">
                                        <label>From Date</label>
                                        <ModernDatepicker
                                            date={add.from && add.from}
                                            format={'YYYY-MM-DD'}
                                            icon={false}
                                            onChange={(e) => fromchangeHandler(e)}
                                            placeholder={'Select From date'}
                                            className={(submitted && !add.from ? ' is-invalid' : '')}
                                        />
                                        {submitted && !add.from &&
                                            <div className="invalid-feedback">From date is required</div>
                                        }
                                    </div>
                                    <div className="form-group flt">
                                        <label>To Date</label>
                                        <ModernDatepicker
                                            date={add.to && add.to}
                                            format={'YYYY-MM-DD'}
                                            showBorder
                                            onChange={(e) => tochangeHandler(e)}
                                            minDate={add.from && add.from}
                                            placeholder={'Select To date'}
                                            className={(submitted && !add.to ? ' is-invalid' : '')}
                                        />
                                        {submitted && !add.to &&
                                            <div className="invalid-feedback">To date is required</div>
                                        }
                                    </div>

                                    <div className="form-group flt">
                                        <label>Upload file</label>
                                        <div className="adv_upload flt">
                                            <div className="adv_image flt">
                                                {isImageUrl && imagePreviewUrl &&
                                                    <img src={imagePreviewUrl} alt="white-rice" />
                                                }
                                            </div>
                                            <div className="adv_video flt">
                                                {!isImageUrl && videoPreviewUrl &&
                                                    <video width="100%" controls>
                                                        <source src={videoPreviewUrl} type="video/mp4" />
                                                        Your browser does not support HTML5 video.
                                                    </video>
                                                }
                                            </div>
                                        </div>
                                        <input type="file" accept="image/*, video/*" onChange={changeFileHandler} ref={ref} className={(submitted && !imagePreviewUrl && !videoPreviewUrl) || (submitted && !validFile) || !limitFile ? ' is-invalid' : ''} />
                                        {submitted && !imagePreviewUrl && !videoPreviewUrl && validFile && limitFile &&
                                            <div className="invalid-feedback">File is required</div>
                                        }
                                        {!validFile &&
                                            <div className="invalid-feedback">File must be either image or video</div>
                                        }
                                        {validFile && !limitFile &&
                                            <div className="invalid-feedback">File size must be less than 50 Mb</div>
                                        }
                                    </div>


                                    <div className="adv_btn flt">
                                        {!isOpened && (
                                            <button type="button" onClick={handleSubmit}>Add</button>
                                        )}
                                        {isOpened && (
                                            <button type="button" onClick={submitEdit} >Update</button>
                                        )}
                                        {isOpened && (
                                            <button type="button" onClick={handleCancel} >Cancel</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <div className="adv_right flt">
                                {loader && <Loader
                                    type="Oval"
                                    color="rgb(40 44 52)"
                                    height={100}
                                    width={100}
                                    className="loader"
                                />}
                                {isOpened &&
                                    <div className="adv_overlay"></div>
                                }
                                <div className="search_adv flt">
                                    <input type="seach" placeholder="Search for store name" onKeyUp={(e) => searchHandler(e)} />
                                </div>
                                <div className="adv_table flt">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Sl. No.</th>
                                                <th>Store Name</th>
                                                <th>Amount/Day</th>
                                                <th>From Date</th>
                                                <th>To Date</th>
                                                <th>File</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.data && data.data.advertisements.docs != null ? data.data.advertisements.docs.map((list, index) =>
                                                <tr key={list._id}>
                                                    <td>{(10 * ( data.data.advertisements.page - 1)) + index + 1}.</td>
                                                    <td><div className="ellipse_cls">{list.ads_info}</div></td>
                                                    <td>¥{list.amount}</td>
                                                    <td>{list.from}</td>
                                                    <td>{list.to}</td>
                                                    <td className="adv_file">
                                                        {(list.content_type == 'PNG' || list.content_type == 'JPG' || list.content_type == 'JPEG') &&
                                                            <img src={adv_file + list.upload_file} alt="white-rice" />
                                                        }
                                                        {(list.content_type == 'MP4' || list.content_type == 'WEBM' || list.content_type == 'AVI') &&
                                                            <video width="100%" controls>
                                                                <source src={adv_file + list.upload_file} type="video/mp4" />
                                                            </video>
                                                        }
                                                    </td>
                                                    <td><button type="button" onClick={() => handleEdit(list._id)}><i className="fas fa-pencil-alt"></i></button></td>
                                                    <td><button type="button" onClick={() => handleDelete(list._id)}><i className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            ) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination flt">
                                    <Pagination
                                        currentPage={current}
                                        totalSize={data.data && data.data.advertisements.totalPages * data.data.advertisements.limit}
                                        showFirstLastPages={true}
                                        changeCurrentPage={changeCurrentPage}
                                        numberOfPagesNextToActivePage={5}
                                        theme="bootstrap"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advertisement
