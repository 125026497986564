import React, { useState, useEffect } from 'react'
import PageHead from '../../_common/PageHead/PageHead'
import Sidebar from '../../_common/Sidebar/Sidebar'
import { plansService } from '../../../_services'
import Pagination from "react-pagination-js";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { alertActions } from '../../../_actions/alert.actions';
import { useDispatch } from 'react-redux';
import { arrayExpression } from '@babel/types';
import message from '../../_common/Message/message';
import Loader from "react-loader-spinner";


function Plans() {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [current, setcurrent] = useState(1);
    const [add, setAdd] = useState({
        title: '',
        amount: '',
        support_mobile: 0,
        support_email: 0
    })
    const [submitted, setSubmitted] = useState(false);
    const [numberValid, setNumberValid] = useState(true);
    const [isOpened, setIsOpened] = useState(false);//add and edit button toggle
    const [mobileChecked, setMobileChecked] = useState(false);// mobile checkbox toggle
    const [emailChecked, setEmailChecked] = useState(false);// email checkbox toggle
    const [loader, setLoader] = useState(false); // loader

    useEffect(() => {
        setLoader(true);
        plansService.getData(current)
            .then((data) => {
                setData(data);
                setLoader(false);
            })
    }, [current])
    const changeCurrentPage = (page) => {
        setcurrent(page)

    }
    function handleCheckboxClick(type, e) {
        if (type == 'mobile' && e.target.checked) { // this click was to check the box
            add.support_mobile = 1;
            setMobileChecked(true)
        }
        else if (type == 'mobile' && !e.target.checked) {
            add.support_mobile = 0;
            setMobileChecked(false)
        }
        if (type == 'email' && e.target.checked) {
            add.support_email = 1;
            setEmailChecked(true)
        }
        else if (type == 'email' && !e.target.checked) {
            add.support_email = 0;
            setEmailChecked(false)
        }
        console.log(add.support_email)
        console.log(add.support_mobile)

    }



    const handleChange = (e) => {
        const { name, value } = e.target;
        setAdd(add => ({ ...add, [name]: value }))
        if (e.target.name == 'amount') {
            var filter = /^[0-9]*$/;
            if (!filter.test(e.target.value)) {
                setNumberValid(false)
            } else {
                setNumberValid(true)
            }
        } // number validation
    }
    // initialize plan addition
    const [formValues, setFormValues] = useState([{ service: '' }])

    // handle change for plan addition
    const handlePlanChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    // add more plan
    const morePlan = () => {
        setFormValues([...formValues, { service: '' }])
    }

    // delete plan addition
    const deletePlan = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }



    const handleSubmit = (e) => {
        console.log(add)
        e.preventDefault();
        setSubmitted(true);
        var x = 0;
        if (add.title.trim() && add.amount && numberValid) {
            formValues.forEach(serval => {
                if (!serval.service.trim()) {
                    x++;
                }
            });
            if (!x) {
                confirmAlert({
                    title: message.title,
                    message: message.content.add,
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => plansService.add(add, formValues)
                                .then((data) => {
                                    if (data.status.code == 0) {
                                        setAdd({
                                            title: '',
                                            amount: ''
                                        });
                                        setFormValues([{ service: '' }]);
                                        setMobileChecked(false);
                                        setEmailChecked(false);
                                        plansService.getData(current)
                                            .then((data) => setData(data))
                                        setSubmitted(false);
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }
                                })
                        },
                        {
                            label: 'No',
                            onClick: () => null
                        }
                    ]
                });
                setSubmitted(false);
            }
        }
    }

    const handleEdit = (id) => {
        plansService.getEdit(id)
            .then((data) => {
                if (data.status.code == 0) {
                    setAdd({
                        id: data.data && data.data.id ? data.data.id : '',
                        title: data.data && data.data.title ? data.data.title : '',
                        amount: data.data && data.data.amount ? data.data.amount : '',
                        support_mobile: data.data && data.data.support_mobile ? data.data.support_mobile : '',
                        support_email: data.data && data.data.support_email ? data.data.support_email : ''
                    });
                    if (data && data.data && data.data.support_mobile == 1) {
                        setMobileChecked(true)
                    }
                    else {
                        setMobileChecked(false)
                    };
                    if (data && data.data && data.data.support_email == 1) {
                        setEmailChecked(true)
                    }
                    else {
                        setEmailChecked(false)
                    }
                    // if (formValues) {
                    //     formValues = [];
                    // }
                    setFormValues(
                        data.data.service
                    )
                    handleChange({
                        target: {
                            name: 'amount',
                            value: data.data.amount
                        }
                    });
                    setIsOpened(true);
                }
            })
    }

    const submitEdit = (e) => {
        setSubmitted(true);
        var x = 0;
        console.log(formValues)
        if (add.title.trim() && add.amount && numberValid) {

            formValues.forEach(serval => {
                if (!serval.service.trim()) {
                    x++;
                }
            });
            if (!x) {
                confirmAlert({
                    title: message.title,
                    message: message.content.update,
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => {
                                e.preventDefault();
                                plansService.edit(add, formValues)
                                    .then((data) => {
                                        if (data.status.code === 0) {
                                            setAdd({
                                                title: '',
                                                amount: ''
                                            });
                                            setFormValues([{ service: '' }])
                                            setIsOpened(false);
                                            setMobileChecked(false);
                                            setEmailChecked(false);
                                            plansService.getData(current)
                                                .then((data) => setData(data));
                                            setcurrent(1);
                                            dispatch(alertActions.success(data.status.message));
                                            setSubmitted(false);
                                            setTimeout(
                                                () => (dispatch(alertActions.clear())),
                                                2000
                                            );
                                        }
                                    })
                            }
                        },
                        {
                            label: 'No',
                            onClick: () => null
                        }
                    ]
                });
            }
            // setSubmitted(false);
        }
    }


    const handleDelete = (id) => {
        confirmAlert({
            title: message.title,
            message: message.content.delete,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => plansService.delete(id)
                        .then((data) => {
                            if (data.status.code === 0) {
                                plansService.getData(current)
                                    .then((data) => setData(data))
                                setcurrent(1);
                                dispatch(alertActions.success(data.status.message));
                                setTimeout(
                                    () => (dispatch(alertActions.clear())),
                                    2000
                                );
                            }
                            if (data.status.code === 1) {
                                dispatch(alertActions.error(data.status.message));
                                setTimeout(
                                    () => (dispatch(alertActions.clear())),
                                    2000
                                );
                            }
                        })
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]

        })
    }

    const handleCancel = (e) => {
        e.preventDefault();
        setAdd({
            title: '',
            amount: ''
        });
        setFormValues([{ service: '' }]);
        setIsOpened(false);
        setMobileChecked(false);
        setEmailChecked(false);
    }

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container flt">
                <PageHead title="Plans" />
                <div className="adv_main flt">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="adv_left flt">
                                <form className="add_adv flt">
                                    <div className="form-group flt">
                                        <label>Title</label>
                                        <input type="text" name="title" value={add.title} onChange={handleChange} className={submitted && !add.title.trim() ? ' is-invalid' : ''} />
                                        {submitted && !add.title.trim() &&
                                            <div className="invalid-feedback">Title is required</div>
                                        }
                                    </div>
                                    <div className="form-group flt">
                                        <label>Amount</label>
                                        <input type="text" name="amount" value={add.amount} onChange={handleChange} className={(submitted && !add.amount) || (!numberValid && add.amount) ? ' is-invalid' : ''} />
                                        {submitted && !add.amount &&
                                            <div className="invalid-feedback">Amount is required</div>
                                        }
                                        {!numberValid && add.amount &&
                                            <div className="invalid-feedback">Please enter valid Amount</div>
                                        }
                                    </div>
                                    <div className="flt">
                                        <label>Services</label>
                                        {formValues.map((element, index) => (
                                            <div className="more_plan flt" key={index}>
                                                <input type="text" name="service" value={element.service || ""} onChange={e => handlePlanChange(index, e)} className={(submitted && element.service.trim() == "") ? ' is-invalid' : ''} />
                                                {submitted && element.service.trim() == "" &&
                                                    <div className="invalid-feedback">Service is required</div>
                                                }
                                                {index ?
                                                    <div className="plan_delete">
                                                        <i className="fas fa-trash-alt" onClick={() => deletePlan(index)}></i>
                                                    </div> : null
                                                }
                                            </div>
                                        ))}
                                        <div className="add_plan flt">
                                            <i className="far fa-plus-square" onClick={morePlan}></i>
                                        </div>
                                    </div>
                                    <div className="flt">
                                        <label>Support required</label>
                                        <div className="facility">
                                            <input type="checkbox" id="support_mobile" name="support_mobile" value="0" checked={mobileChecked} onChange={(e) => handleCheckboxClick('mobile', e)} />
                                            <label htmlFor="support_mobile">Mobile</label>
                                        </div>
                                        <div className="facility">
                                            <input type="checkbox" id="support_email" name="support_email" value="0" checked={emailChecked} onChange={(e) => handleCheckboxClick('email', e)} />
                                            <label htmlFor="support_email">Email</label>
                                        </div>
                                    </div>
                                    <div className="adv_btn flt">
                                        {!isOpened && (
                                            <button type="button" onClick={handleSubmit}>Add</button>
                                        )}
                                        {isOpened && (
                                            <button type="button" onClick={submitEdit}>Update</button>
                                        )}
                                        {isOpened && (
                                            <button type="button" onClick={handleCancel}>Cancel</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <div className="adv_right flt">
                                {loader && <Loader
                                    type="Oval"
                                    color="rgb(40 44 52)"
                                    height={100}
                                    width={100}
                                    className="loader"
                                />}
                                {isOpened &&
                                    <div className="adv_overlay"></div>
                                }
                                <div className="adv_table flt">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Sl. No.</th>
                                                <th>Title</th>
                                                <th>Amount</th>
                                                <th>Services</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.data && data.data.plan && data.data.plan.docs != null ? data.data.plan.docs.map((list, index) =>
                                                <tr key={index}>
                                                    <td>{(10 * (data.data.plan.page - 1)) + index + 1}.</td>
                                                    <td><div className="ellipse_cls">{list.title}</div></td>
                                                    <td><div className="ellipse_cls">{list.amount}</div></td>
                                                    <td><ul>{list.service && list.service.map((ser, index) => <li key={index}><div className="ellipse_cls">{(ser.service + ' ')}</div></li>)}</ul></td>
                                                    <td><button onClick={() => handleEdit(list._id)}><i className="fas fa-pencil-alt"></i></button></td>
                                                    <td><button onClick={() => handleDelete(list._id)}><i className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            ) : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination flt">
                                    <Pagination
                                        currentPage={current}
                                        totalSize={data.data && data.data.plan.totalPages * data.data.plan.limit}
                                        showFirstLastPages={true}
                                        changeCurrentPage={changeCurrentPage}
                                        numberOfPagesNextToActivePage={5}
                                        theme="bootstrap"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Plans
