import React, { useState, useEffect } from 'react';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { dashboardService } from '../../../_services';

function Dashboard() {
    const [data, setData] = useState([]);
    useEffect(() => {
        dashboardService.getData()
            .then((data) => setData(data))
    }, []);

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container flt">
                <PageHead title="Dashboard" />
                <div className="dashboad_main flt">
                    <div className="row no_margin">
                        <div className="col-sm-4">
                            <div className="dashbox pink flt">
                                <div className="dash_icon">
                                    <span><i className="fas fa-users"></i></span>
                                </div>
                                <div className="dashbox_cont">
                                    <div className="dashtag flt">
                                        Merchant
                                    </div>
                                    <div className="dash_number flt">
                                        {data.data && data.data.dashboard && data.data.dashboard.merchant}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="dashbox green flt">
                                <div className="dash_icon">
                                    <span><i className="fas fa-ad"></i></span>
                                </div>
                                <div className="dashbox_cont">
                                    <div className="dashtag flt">
                                        Advertisement
                                    </div>
                                    <div className="dash_number flt">
                                        {data.data && data.data.dashboard && data.data.dashboard.advertisement}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="dashbox blue flt">
                                <div className="dash_icon">
                                    <span><i className="fas fa-money-check-alt"></i></span>
                                </div>
                                <div className="dashbox_cont">
                                    <div className="dashtag flt">
                                        Plan
                                    </div>
                                    <div className="dash_number flt">
                                        {data.data && data.data.dashboard && data.data.dashboard.plan}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
