import React, { useEffect, useState } from 'react'
import { privacyService } from '../../../_services'
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { Link } from "react-router-dom";

const PrivacyPreview = () => {
    const [data, setData] = useState([]);
    let type = "privacy"
    useEffect(() => {
        privacyService.getData(type)
            .then((data) => setData(data.data))


    }, [])

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="PRIVACY POLICY" />
                <div className="preview_main flt">
                    <div className="preview_box flt">
                        <div className="prview_head flt">
                            <Link to="privacy-policy">Edit <i className="far fa-edit"></i></Link>
                        </div>
                        <div className="preview_cont flt" dangerouslySetInnerHTML={{ __html: data[0] && data[0].content }}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPreview
