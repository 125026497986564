import { authHeader } from '../_helpers';
import axios from 'axios'

export const advertisementService = {
    getData,
    add,
    edit,
    getEdit,
    delete: _delete
};

// const baseurl = process.env.REACT_APP_BASE_URL;
// const fetchURL = `${baseurl}advertisement`


async function getData(pno, search) {
    //let pno = 2;
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}advertisement?page=${pno}`+'&search='+search,
        headers: authHeader()
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function add(add, upload_file) {
    var bodyFormData = new FormData();
    bodyFormData.append("ads_info",add.ads_info)
    bodyFormData.append("amount",add.amount)
    bodyFormData.append("from",add.from)
    bodyFormData.append("to",add.to)
    bodyFormData.append("upload_file",upload_file)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}advertisement`,
        data: bodyFormData
    };


    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function edit(add, upload_file) {
    var bodyFormData = new FormData();
    bodyFormData.append("upload_file",upload_file)
    bodyFormData.append("ads_info",add.ads_info)
    bodyFormData.append("amount",add.amount)
    bodyFormData.append("from",add.from)
    bodyFormData.append("to",add.to)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}advertisement/`+add.id,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);

}


async function getEdit(id) {
    console.log(id)
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}advertisement/${id}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    console.log(id);
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_BASE_URL}advertisement/${id}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);

}






function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}