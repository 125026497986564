import React, { useState } from 'react';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { alertActions } from '../../../_actions/alert.actions'
import { useDispatch } from 'react-redux';
import { settingService } from '../../../_services';
import message from '../../_common/Message/message';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


function AccountSetting() {
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [update, setUpdate] = useState({
        email: '',
        password: '',
        new_password: '',
        confirm_password: ''
    });

    const changeHandler = (e) => {
        if (e.target.name == 'email') {
            var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!filter.test(e.target.value)) {
                setEmailValid(false)
            } else {
                setEmailValid(true)
            }
        } // Email validation


        if ((e.target.name == 'confirm_password') || (e.target.name == 'new_password')) {
            if ((update.new_password == e.target.value) || (update.confirm_password == e.target.value)) {
                setPasswordValid(true);
            }
            else {
                setPasswordValid(false);
            }
        } // confirm password validation

        const { name, value } = e.target;
        setUpdate(data => ({ ...data, [name]: value }));
    }


    const handleCheck = (e) => {
        if (e.target.checked) { // this click was to check the box
            setShowPassword(true);
        }
        else {
            setShowPassword(false);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if ((showPassword && update.email.trim() && update.password.trim() && update.new_password.trim() && passwordValid) || (!showPassword && update.email.trim() && update.password.trim())){
            confirmAlert({
                title: message.title,
                message: message.content.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            settingService.update(update)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                        setUpdate({
                                            email: '',
                                            password: '',
                                            new_password: '',
                                            confirm_password: ''
                                        });
                                        setSubmitted(false);
                                    }
                                    else {
                                        dispatch(alertActions.error(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }

                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });
        }
    }

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container flt">
                <PageHead title="Setting" />
                <div className="account_main flt">

                    <div className="account_head flt">Change Email/Password</div>
                    <div className="account_cont flt">
                        <form className="flt">
                            <div className="form-group flt">
                                <label>Email</label>
                                <input type="email" name="email" value={update.email} onChange={changeHandler} className={((submitted && !update.email.trim()) || (!emailValid && update.email.trim()) ? ' is-invalid' : '')} />
                                {submitted && !update.email.trim() &&
                                    <div className="invalid-feedback">
                                        Email ID is required
                                    </div>
                                }
                                {!emailValid && update.email.trim() &&
                                    <div className="invalid-feedback">
                                        Please enter valid Email ID
                                    </div>
                                }
                            </div>
                            <div className="account_check flt">
                                <input type="checkbox" id="change_password" name="change_password" onChange={(e) => handleCheck(e)} />
                                <label htmlFor="change_password">Change Password</label>
                            </div>

                            <div className="form-group flt">
                                <label>Current Password</label>
                                <input type="password" name="password" value={update.password} onChange={changeHandler} className={(submitted && !update.password.trim() ? ' is-invalid' : '')} />
                                {submitted && !update.password.trim() &&
                                    <div className="invalid-feedback">
                                        Current password is required
                                    </div>
                                }
                            </div>
                            {showPassword &&
                                <div className="account_password flt">
                                    <div className="form-group flt">
                                        <label>New Password</label>
                                        <input type="password" name="new_password" value={update.new_password} onChange={changeHandler} className={(submitted && !update.new_password.trim() ? ' is-invalid' : '')} />
                                        {submitted && !update.new_password.trim() &&
                                            <div className="invalid-feedback">
                                                New password is required
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group flt">
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirm_password" value={update.confirm_password} onChange={changeHandler} className={((submitted && !update.confirm_password.trim() || (update.confirm_password.trim() && !passwordValid)) ? ' is-invalid' : '')} />
                                        {submitted && !update.confirm_password.trim() &&
                                            <div className="invalid-feedback">
                                                Confirm password is required
                                            </div>
                                        }
                                        {update.confirm_password.trim() && !passwordValid &&
                                            <div className="invalid-feedback">
                                                Password did not match
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="account_btn flt">
                                <button type="button" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AccountSetting
