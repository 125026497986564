import { authHeader } from '../_helpers';
import axios from 'axios'

export const dashboardService = {
    getData
};


async function getData() {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}dashboard`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}